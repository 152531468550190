import React, { useState } from 'react';
import ProfileLogo from "./img/ExteriorLogo.png";
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const ExteriorForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  // CSS as a function
  const styles = {
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e7f3ff',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '10px'
    },
    h2: {
      margin: 0,
      fontSize: '24px',
      color:'#0050A4',
      fontWeight:'600',
    },
    toggleButton: {
      backgroundColor: 'transparent',
      border: '1px solid #0050A4',
    //   padding:'4px',
      borderRadius:'8px',
      fontSize: '18px',
      cursor: 'pointer',

    },
    formContent: {
    //   backgroundColor: '#f9f9f9',
      padding: '35px',
      borderRadius: '10px',
      border: '1px solid rgba(0, 80, 164, 0.5)',
      color:'rgba(0, 80, 164, 0.5)',
    },
    formGroup: {
        // padding:'20px',
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '16px'
    },
  
    inputRadioCheckbox: {
        width:'5%',
      marginRight: '8px',

    },
    photoUpload: {
      display: 'flex',
      gap: '20px'
    },
    photoUploadImg: {
    //   width: '100px',
      backgroundColor:'rgba(191, 207, 223, 0.4)',

    //   height: '100px',
      padding:'10px',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  };

  return (
    <div>
      {/* Button to toggle the form */}
      <div style={styles.formHeader}>
        <span style={styles.h2}>Facility and Business Operations</span>
        <button  onClick={toggleForm} style={styles.toggleButton}>
          {isOpen ?  <i class="fa-solid fa-minus"></i>: <i class="fa-solid fa-plus"></i> }
        </button>
      </div>

      {/* Conditional rendering of the form */}
      {isOpen && (
        <div style={styles.formContent}>
          <div style={styles.formGroup}>
            <label style={styles.label}>P2. A-PHOTO Take a photo of the closed front door to the business.</label>
            <div style={styles.photoUpload}>
              <img src={ProfileLogo} alt="Upload 1" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 2" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 3" style={styles.photoUploadImg} />
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D4. Is this facility rented/leased or owned?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Rented/leased</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">Owned</label><br />

            
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D5. Provide the square footage of the building.</label>
            <div>
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Brick/masonry</label><br />

             
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>P3. A-PHOTO Is there permanent signage for the company at this location?</label>
            <div style={styles.photoUpload}>
              <img src={ProfileLogo} alt="Upload 1" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 2" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 3" style={styles.photoUploadImg} />
            </div>
          </div>
          

          <div style={styles.formGroup}>
            <label style={styles.label}>D6. Provide the count for the following at this facility.</label>
            <div>
              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Brick/masonry</label><br />

         
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D7. Are any other businesses located at the inspected address?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Yes. List the name(s):</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">No</label><br />

            
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D8. How do employees access secured areas? Check all that apply.</label>
           <div className='row ms-2 mt-1'>
            <div className='col-lg-6' >
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Badge</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Keypad</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">RFID</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Biometrics {"("}thumbprint; retinal scan</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Key/lock</label><br />

             
            </div>
            <div className='col-lg-6' >
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Combination lock</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Gate remote</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Employees are let in by other employees</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Other. Please explain:</label><br />

             
            </div>
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D9. How do visitors gain access to secured areas?</label>
            <div>
              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">By appointment</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Escorted by employee</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Open access - gate/office open during business hours</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">N/A. Visitors are never allowed into secured areas.</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Other. Please explain:</label><br />

         
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D10. How are assignments given to drivers? Check all that apply.</label>
           <div className='row ms-2 mt-1'>
            <div className='col-lg-6' >
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Desktop</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Laptop</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Mobile device (phone or tablet)</label><br />
             
             
            </div>
            <div className='col-lg-6' >
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Fax</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Paper</label><br />
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Other. Please explain:</label><br />
             

             
            </div>
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D11. Is there a written training manual and/or company handbook?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Yes</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">No</label><br />

            
            </div>
          </div>
         
          <div style={styles.formGroup}>
            <label style={styles.label}>D12. Is there a written disaster recovery plan?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Yes</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">No</label><br />

            
            </div>
          </div>
       
          <div style={styles.formGroup}>
            <label style={styles.label}>D13. Is any part of the facility being used for living quarters?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Yes, please explain</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">No</label><br />

            
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D14. Is there a separate entrance for debtors who are retrieving vehicles or personal property?</label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Yes, please explain</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">No</label><br />

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">N/A. Vehicle/personal property redemption does not take place at this location.</label><br />

            
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D15. Are the following posted? Check all that apply.</label>
            <div>
              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">State regulations</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">State repossession license</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Business license</label><br />

              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Other operating licenses</label><br />

             

         
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default ExteriorForm;
