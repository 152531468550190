// RevenueOverview.js
import React from 'react';
import './RevenueOverview.css';
import totalRevenueGeneratedIcon from './img/totalRevenueGeneratedIcon.png';

const RevenueOverview = () => {
  return (
    <div className="revenue-overview " style={{paddingBottom:"2px"}}>
      <span style={{fontSize:"21px",fontWeight:"bold"}} >Revenue Overview</span>
      <div className="revenue-box ">
        <div className="revenue-content">
          <span>Total Revenue Generated</span>
          <span className="revenue-amount">$1.5M</span>
        </div>
        <div className="icon-container">
          <img src={totalRevenueGeneratedIcon} alt="icon" />
        </div>
      </div>
      <div className="revenue-box mt-5">
        <div className="revenue-content">
          <div className="revenue-inline">
            <span>Revenue Generated In</span>
            <div className="revenue-month">
           
              <span><button>{"<"}</button> July 2024 <button>{">"}</button> </span>
           
            </div>
          </div>
          <span className="revenue-amount">$2.5M</span>
        </div>
        <div className="icon-container">
          <img src={totalRevenueGeneratedIcon} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default RevenueOverview;
