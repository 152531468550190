import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import './YearlyOverviewChart.css';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';

const YearlyOverviewChart = () => {
  const [activeTab, setActiveTab] = useState('Requests');

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    colors: ['rgba(168, 197, 218, 1)', 'rgba(31, 146, 84, 1)', 'rgba(157, 199, 184, 1)', 'rgba(117, 73, 255, 1)'],
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          colors: '#666666',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#666666',
          fontSize: '12px',
        },
        formatter: function(value) {
          return Math.round(value);
        },
      },
    },
    grid: {
      borderColor: '#e0e0e0',
    },
    legend: {
      position: 'top',
      horizontalAlign:'right',  // Align legend to the right
      marginLeft:'10px',
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  const chartData = {
    Requests: [
      {
        name: 'New Request',
        data: [200, 220, 250, 280, 320, 340, 360, 380, 400, 380, 360, 340],
      },
      {
        name: 'Assigned Request',
        data: [150, 170, 190, 210, 250, 280, 300, 320, 340, 320, 300, 280],
      },
      {
        name: 'To-Be-Approved Request',
        data: [180, 200, 220, 240, 260, 300, 320, 340, 360, 340, 320, 300],
      },
      {
        name: 'Approved Inspection',
        data: [250, 260, 300, 320, 350, 380, 400, 420, 450, 430, 410, 390],
      },
    ],
    Companies: [
      {
        name: 'New Companies',
        data: [50, 60, 55, 70, 65, 80, 85, 90, 95, 85, 80, 75],
      },
      {
        name: 'Active Companies',
        data: [30, 40, 35, 50, 55, 60, 65, 70, 75, 65, 60, 55],
      },
    ],
    Sites: [
      {
        name: 'New Sites',
        data: [100, 120, 110, 130, 120, 140, 135, 150, 160, 150, 140, 130],
      },
      {
        name: 'Active Sites',
        data: [70, 80, 75, 90, 85, 95, 100, 110, 120, 110, 100, 90],
      },
    ],
  };

  return (
    <div className="yearly-overview-chart" style={{ width: '98%' }}>
      <h3 className="chart-title" style={{ backgroundColor: 'white' }}>Yearly Overview</h3>
      <p className="chart-subtitle">Case overview of each month</p>
      
      <div className="chart-tabs">
        <span
          className={`tab ${activeTab === 'Requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('Requests')}
        >
          Requests
        </span>
        <span
          className={`tab ${activeTab === 'Companies' ? 'active' : ''}`}
          onClick={() => setActiveTab('Companies')}
        >
          Companies
        </span>
        <span
          className={`tab ${activeTab === 'Sites' ? 'active' : ''}`}
          onClick={() => setActiveTab('Sites')}
        >
          Sites
        </span>
      </div>

      {/* Legend moved outside of the chart */}
      <div className="chart-legend">
        <Chart
          options={chartOptions}
          series={chartData[activeTab]}
          type="line"
          height={400}
        />
      </div>
    </div>
  );
};

export default YearlyOverviewChart;
