import React from 'react';
import Chart from 'react-apexcharts';
import './CircularChartComponent.css';
import { NavLink } from 'react-router-dom';

const CircularChartComponent = () => {
  const chartOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '45%',
        },
        track: {
          background: '#f0f4f8',
        },
        dataLabels: {
          show: false,
        },
      },
    },
    colors: ['#5D8DEC', '#3366CC', '#476195', '#8DADEB'],
    labels: ['New Requests', 'In-Progress Requests', 'To-Be-Approved Requests', 'Approved Inspections'],
  };

  const chartSeries = [261, 165, 82, 47];

  return (
    <div className="circular-chart-container">
      <div className="circular-chart-header">
        <h3 style={{backgroundColor:'white', fontWeight:'bold'}}>Requests Status</h3>
        <div className="date-selector">
          <span>&lt;</span>
          <span>June 2024</span>
          <span>&gt;</span>
        </div>
      </div>
      <div className="circular-chart-body">
        <Chart options={chartOptions} series={chartSeries} type="radialBar" height={310} />
        <div className="request-list" style={{textDecoration:'none'}}> 
  <NavLink to="/new-request" className="request-item">
    <span className="dot blue"></span>
    <span>New Requests</span>
    <span className="request-count">261</span>
  </NavLink>
  <NavLink to="/in-progress" className="request-item">
    <span className="dot grey"></span>
    <span>In-Progress Requests</span>
    <span className="request-count">165</span>
  </NavLink>
  <NavLink to="/to-be-approved" className="request-item">
    <span className="dot light-blue"></span>
    <span>To-Be-Approved Requests</span>
    <span className="request-count">82</span>
  </NavLink>
  <NavLink to="/approved-Request" className="request-item">
    <span className="dot white"></span>
    <span>Approved Inspections</span>
    <span className="request-count">708</span>
  </NavLink>
</div>
      </div>
    </div>
  );
};

export default CircularChartComponent;
