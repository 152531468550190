import React, { useEffect, useState } from 'react';
import deleteIcon from '../img/deleteIcon.png'; // Make sure this path is correct
import config from "../../../../../config/config.json";
import { apiCall } from '../../../../../services/ApiCall.js';
import { useNavigate } from 'react-router-dom';

const MainContact = ({ onPrevious, companyData }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([{
    firstName: 'Archie',
    lastName: 'Bizmark',
    email: '1stchoice@recoveryagents.net',
    phone: '8438514377',
    designation: 'Owner',
  }]);

  const getCompanyContact = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyContact`,
      {},
      { companyId: companyData.id },
      "GET"
    );
    if (isSuccess) {
      setFormData(data?.data?.comapnyUserData || []);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getCompanyContact();
  }, [companyData.id]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    setFormData(updatedFormData);
  };

  const handleSave = async() => {
    console.log('Saved data:', formData);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}updateCompanyData`,
      {},
      { comapnyId: companyData.id, ...companyData},
      "POST"
    );
    if (isSuccess) {
      // setFormData(data?.data?.comapnyUserData || []);
    }
  };

  const styles = {
    container: {
      backgroundColor: '#f0f8ff',
    },
    title: {
      fontSize: '32px',
      color: '#0050A4',
      marginBottom: '20px',
    },
    header: {
      backgroundColor: '#0050A4',
      color: 'white',
      padding: '10px',
      fontSize: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
    },
    form: {
      backgroundColor: '#E2EDF9',
      padding: '20px',
    },
    formGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      color: '#0050A4',
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #0050A4',
      color: '#0050A4',
      borderRadius: '4px',
    },
    select: {
      width: '100%',
      padding: '8px',
      border: '1px solid #0050A4',
      color: '#0050A4',
      borderRadius: '4px',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#0050A4',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    addButton: {
      padding: '2px 10px',
      backgroundColor: 'white',
      color: '#1C1C1C',
      border: '1px solid white',
      borderRadius: '4px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    deleteIcon: {
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
  };

  return (
    <>
      <div style={{ padding: '20px' }}>
        <h1 style={styles.title}>Edit</h1>
        <div style={styles.header}>
          <span>Main Contact</span>
          <div style={styles.headerActions}>
            <button style={styles.addButton}>
              <span>+</span> Add
            </button>
            <img src={deleteIcon} alt="Delete" />
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.form}>
            {formData.map((contact, index) => (
              <div key={index}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>First Name</label>
                      <input
                        style={styles.input}
                        type="text"
                        name="firstName"
                        value={contact.firstName}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Last Name</label>
                      <input
                        style={styles.input}
                        type="text"
                        name="lastName"
                        value={contact.lastName}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Email</label>
                      <input
                        style={styles.input}
                        type="email"
                        name="email"
                        value={contact.email}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Phone</label>
                      <input
                        style={styles.input}
                        type="tel"
                        name="phone"
                        value={contact.phone}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={styles.formGroup}>
                      <label style={styles.label}>Designation</label>
                      <select
                        style={styles.select}
                        name="designation"
                        value={contact.designation}
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option value="Owner">Owner</option>
                        <option value="Manager">Manager</option>
                        <option value="Employee">Employee</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div style={styles.buttonContainer}>
              <button style={styles.button} onClick={onPrevious}>
                Previous
              </button>
              <button style={styles.button} onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContact;
