import React from 'react';
import mysiteInspectionLogo from './img/mysiteInspectionLogo.png';
import ExteriorForm from './ExteriorForm';
import FacilityBusinessOperations from './FacilityandBusinessOperations';

const SiteInspectionReport = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <div style={styles.headerLeft}>
          <button style={styles.headerButton}>Edit Request</button>
          <button style={styles.headerButton}>Sync To Zoho</button>
        </div>
        {/* <div style={styles.headerRight}>
        
          <div style={styles.contactInfo}>
        
            
          </div>
        </div> */}
      </header>
      <div className='header' style={{backgroundColor:'#DCEDFF',padding:'30px',borderRadius:'10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} >
        <div>  
            <img
            src={mysiteInspectionLogo}   alt="MySiteInspection.com"
            style={styles.logo}
          /></div>
      <div>
        <span style={{marginRight:'1em' }}>Support@mysiteinspection.com</span>
      <strong style={{marginRight:'1em'}}>401.463.0200</strong>
      </div>
      </div>
      <main style={styles.reportContent}>
        <h1 style={styles.title}>Site Inspection Compliance Report</h1>
        <div style={styles.reportDetails}>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Company Name</div>
            <strong style={{fontSize:'18px'}}>State Recovery, Inc (Idaho)</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Contact Name</div>
            <strong style={{fontSize:'18px'}}>Davey Adams</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Contact Number</div>
            <strong style={{fontSize:'18px'}}>2087856591</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Contact Email</div>
            <strong style={{fontSize:'18px'}}>staterecovery@hotmail.com</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Inspection Due Date</div>
            <strong style={{fontSize:'18px'}}>08/05/2024</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Approved Date</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Reference</div>
            <strong style={{fontSize:'18px'}}>AVZMJQD</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Inspector</div>
            <strong style={{fontSize:'18px'}} >Cheri Kraunelis</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Next Renewal Date</div>
            <strong style={{fontSize:'18px'}}>08/05/2024</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Site Admin Contact</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Site Manager Contact</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Admin Contact Phone</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Admin Contact Email</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Address</div>
            <strong style={{fontSize:'18px'}}>123 Main Street  ID 83605</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Site Manager Email</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{fontSize:'12px'}}>Site Manager Phone</div>
            <strong style={{fontSize:'18px'}}>dd/mm/yyy</strong>
          </div>
        </div>
      </main>

       <div>
        <ExteriorForm/>
       </div>
       <div className='mt-3'>
        <FacilityBusinessOperations/>
       </div>

    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: '#f8f8f8',
    // padding: '10px',
    marginBottom:'1em'
    // borderBottom: '1px solid #ddd',
  },
  headerLeft: {
    display: 'flex',
    gap: '10px',
  },
  headerButton: {
    backgroundColor: '#0050A4',
    color: 'white',
    border: 'none',
    marginRight:'2em',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  logo: {
    height: '40px',
  },
  contactInfo: {
    textAlign: 'right',
  },
  reportContent: {
    paddingLeft:'10px',
    marginTop:'2em'

    // padding: '20px',
  },
  title: {
    // textAlign: 'center',
    fontSize:'30px',
    color:'#0050A4',
    // marginTop:'20px',
    marginBottom: '40px',
  },
  reportDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    paddingTop:'0px',
    padding:'10px',
    // padding
    gap: '30px',
    // fontSize:'20px',
    color:'#0050A4',
  },
  reportItem: {
    marginBottom: '10px',
  },
};

export default SiteInspectionReport;
