import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './ProgressCards.css';
import newRequestIcon from './img/newRequestIcon.png';
import inProgressIcon from './img/inProgressIcon.png';
import toBeApproved from './img/toBeApprovedIcon.png';
import approvedRequestIcon from './img/approvedRequest.png';
import redoIcon from './img/redoIcon.png';
import upcomingRequestIcon from './img/upcomingRequest.png';

const ProgressCard = ({ icon, title, value }) => (
  <div className="progress-card">
    <div className="progress-card-content">
      <span className="progress-card-icon">{icon}</span>
      <div className="progress-card-info">
        <span className="progress-card-title">{title}</span>
        <span className="progress-card-value">{value}</span>
      </div>
    </div>
  </div>
);

const ProgressCards = () => {
  const cards = [
    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',padding:'5px',marginLeft:'8px',marginRight:'0px',paddingBottom:'10px'}} >
        <img className='newRequestIcon w-75 h-50' src={newRequestIcon} alt=''/>
      </div>, 
      title: 'New Requests', 
      value: 82, 
      link: '/new-Request' // Add link property 
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'4px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'0px',paddingBottom:'6px'}} > 
        <img className='w-75 h-50' src={inProgressIcon} alt=''/>
      </div>, 
      title: 'In-Progress Request', 
      value: 40, 
      link: '/in-progress' // Add link property 
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'5px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'6px'}}>
        <img src={toBeApproved} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'To-Be-Approved Requests', 
      value: 30 ,
      link: '/to-be-approved'
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'6px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'8px'}}>
        <img src={redoIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Redo Requests', 
      value: 20 ,
      link: '/redo',
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'6px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'8px'}}> 
        <img src={approvedRequestIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Approved Requests', 
      value: 20 ,
      link:'/approved-Request',
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',padding:'5px',marginLeft:'8px',marginRight:'0px',paddingBottom:'10px'}}>
        <img src={upcomingRequestIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Upcoming Requests', 
      value: 70 ,
      link:'/upcoming-request'
    },
  ];

  return (
    <>
      <div className='Progress'>Progress</div>
      <div className="progress-cards-container">
        {cards.map((card, index) => (
          card.link ? (
            <NavLink key={index} style={{ textDecoration: 'none' }} to={card.link}>
              <ProgressCard {...card} />
            </NavLink>
          ) : (
            <ProgressCard key={index} {...card} />
          )
        ))}
      </div>
    </>
  );
};

export default ProgressCards;












/* import React from 'react';
import './ProgressCards.css';
import newRequestIcon from './img/newRequestIcon.png';
import inProgressIcon from'./img/inProgressIcon.png';
import toBeApproved from './img/toBeApprovedIcon.png';
import approvedRequestIcon from './img/approvedRequest.png';
import upcomingRequestIcon from './img/upcomingRequest.png';
const ProgressCard = ({ icon, title, value }) => (
  <div className="progress-card">
    <div className="progress-card-content">
      <span className="progress-card-icon">{icon}</span>
      <div className="progress-card-info">
        <span className="progress-card-title">{title}</span>
        <span className="progress-card-value">{value}</span>
      </div>
    </div>
  </div>
);

const ProgressCards = () => {
  const cards = [
    { icon: <img className='newRequestIcon' src={newRequestIcon} alt=''/>, title: 'New Requests', value: 82 },
    { icon: <img src={inProgressIcon}/>, title: 'In-Progress Request', value: 40 },
    { icon: <img src={toBeApproved}/>, title: 'To-Be-Approved Requests', value: 30 },
    { icon: <img src={approvedRequestIcon}/>, title: 'Approved Requests', value: 20 },
    { icon: <img src={upcomingRequestIcon}/>, title: 'Upcoming Requests', value: 70 },
   

  ];

  return (
<>
    <div className='Prgress'>Progress</div>
    <div className="progress-cards-container">
          
      {cards.map((card, index) => (
        <ProgressCard key={index} {...card} />
      ))}
    </div>
    </>
  );
};

export default ProgressCards;*/