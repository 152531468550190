import React, { useEffect, useState } from 'react';
import editIcon from '../img/editIcon.png';
import deleteIcon from '../img/deleteIcon.png';
import exportIcon from '../img/exportIcon.png';
import config from "../../../config/config.json"
import { Navigate, NavLink, useLocation } from 'react-router-dom';
import { apiCall } from '../../../services/ApiCall';

const ViewPage = ({ status }) => {
  const location = useLocation();
  const [companyData, setCompanyData] = useState({});
  const [companyUserData, setCompanyUserData] = useState([]);

  const getCompanyContact = async () => {
    const match = location.pathname.match(/\/company-list\/view\/(\d+)/)
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyContact`,
      {},
      { companyId: match[1] },
      "GET"
    );
    if (isSuccess) {
      setCompanyUserData(data?.data?.comapnyUserData);
      setCompanyData(data?.data?.companyData);
    }
    else {
      console.log("error")
    }
  };



  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      Navigate("/");
    } else {
      getCompanyContact();
    }
  }, []);



  return (
    <div style={{ margin: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', marginBottom: '2em' }}>
        <div style={{ color: '#0050A4', fontSize: '32px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Company Details
        </div>

        <div style={{ fontSize: '16px', borderRadius: '5px' }}>
          <button style={{ marginRight: '20px', color: 'white', backgroundColor: '#0050A4', padding: '8px 18px', border: '1px solid #0050A4  ' }}>History</button>
          <button style={{ color: '#0050A4', backgroundColor: 'white', padding: '5px 15px', border: '1px solid #0050A4', fontWeight: '600' }}> <span style={{}}><img src={exportIcon} /> </span>Export</button>
        </div>

      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', marginBottom: '3em' }}>
        <NavLink to="/add-company" style={{ textDecoration: 'none' }}>
          <button style={{ backgroundColor: '#0050A4', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            + Add Company
          </button>
        </NavLink>
        <input
          type="text"
          placeholder="Search..."
          style={{ padding: '10px', borderRadius: '10px', border: '1px solid #0050A4', maxWidth: '1000px', width: '70%', float: 'right' }}
        />
      </div>

      <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Company Details</span>
        {/* <div style={{ display: 'flex', gap: '10px' }}>
          <button
            style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}
            onClick={onEdit}
          >
            <img style={{ background: '#E6F1F7', border: '1px solid #E6F1F7', borderRadius: '50%', padding: '8px' }} src={editIcon} alt="Edit" />
          </button>
          <button style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}>
            <img src={deleteIcon} alt="Delete" />
          </button>
        </div> */}
      </div>

      <div style={{ padding: '20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }}>
          <div style={{ flex: '0 1 18%' }}>
            <strong>ARA ID</strong><br />
            {companyData?.araId}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Name</strong><br />
            {companyData?.name}
          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Street</strong><br />
            {companyData?.street}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Street 2</strong><br />
            {companyData?.street2}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>State</strong><br />
            {companyData?.state}

          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }} className='pt-4'>
          <div style={{ flex: '0 1 18%' }}>
            <strong>City</strong><br />
            {companyData?.city}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Zipcode</strong><br />
            {companyData?.zipcode}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Office Type</strong><br />
            {companyData?.officeType}

          </div>
          <div style={{ flex: '0 1 18%' }}>
            <strong>Status</strong><br />
            <span style={{ backgroundColor: status === 'Active' ? '#48AB57' : '#48AB57', padding: '5px 10px', color: 'white', borderRadius: '10px' }}>
              {companyData?.status === 0 ? 'Active' : 'Inactive'}
              <span>   </span>
            </span>
          </div>
          <div style={{ flex: '0 1 18%' }}>
          </div>
        </div>
      </div>








      <div style={{ marginTop: '20px', backgroundColor: '#0050A4', padding: '15px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white', display: 'flex', justifyContent: 'space-between' }} className='mt-5'>
        <span style={{ margin: 0, fontSize: '20px', fontWeight: '400' }}>Main Contact</span>
        {/* <div style={{ display: 'flex', gap: '10px' }}>
          <button style={{ backgroundColor: '#E6F1F7', color: '#0050A4' }} className='ps-2 pe-2'>+ Add New Contact </button>
          <button
            style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}
            onClick={onEdit}
          >
            <img style={{ background: '#E6F1F7', border: '1px solid #E6F1F7', borderRadius: '50%', padding: '8px' }} src={editIcon} alt="Edit" />
          </button>
          <button style={{ backgroundColor: '#0050A4', border: 'none', color: 'white', cursor: 'pointer' }}>
            <img src={deleteIcon} alt="Delete" />
          </button>
        </div> */}
      </div>

      <div style={{ padding: '20px', backgroundColor: '#e9edf5', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
        {companyUserData && companyUserData.length > 0 ? (
          companyUserData.map(user => (
            <div key={user.id} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px', color: '#0050A4' }}>
              <div style={{ flex: '0 1 18%' }}>
                <strong>Name</strong><br />
                <span>{user.name}</span>
              </div>
              <div style={{ flex: '0 1 18%' }}>
                <strong>Phone</strong><br />
                <span>{user.phone}</span>
              </div>
              <div style={{ flex: '0 1 18%' }}>
                <strong>DESIGNATION</strong><br />
                <span>{user.designation}</span>
              </div>
              <div style={{ flex: '0 1 18%' }}>
                <strong>EMAIL</strong><br />
                <span>{user.email}</span>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', color: '#0050A4' }}>No contact added</div>
        )}
      </div>


    </div>
  );
};

export default ViewPage;
