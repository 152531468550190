import React, { useState, useEffect } from "react";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./main.css";
import AddSite from "./AddSite";
import ViewRequest from "./ViewRequest";
import EditSite from "./EditSite";
import viewIcon from "../img/viewIcon.png";
import editIcon from "../img/editIcon.png";
import archiveIcon from "../img/archiveIcon.png";
import deleteIcon from "../img/delete.png";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../CompanyFolder/CompanyList/Loader";

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("main");
  const [selectedSite, setSelectedSite] = useState(null);
  const [deletedMode, setDeletedMode] = useState("DELETE");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAddSite = () => {
    setPage("addSite");
  };

  const handleEdit = (site) => {
    setSelectedSite(site);
    setPage("editSite");
  };

  const handleSaveEdit = (updatedSite) => {
    const updatedData = data.map((site) =>
      site.id === updatedSite.araId ? updatedSite : site
    );
    setData(updatedData);
    setPage("main");
  };

  const handleCancelEdit = () => {
    setPage("main");
  };

  const handleViewRequest = () => {
    setPage("viewRequest");
  };

  const handleActionIconClick = (id, mode) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setDeletedMode(mode);
  };
  const handleAction = () => {
    actionSite(deleteId);
    setShowDeleteModal(false);
  };
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === "#") {
        return direction === "ascending"
          ? a.sequence - b.sequence
          : b.sequence - a.sequence;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };
  const actionSite = async (id) => {
    const endpoint = deletedMode === "DELETE" ? "deleteSite" : "archiveSite";
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}${endpoint}`,
      {},
      { siteId: id },
      "POST"
    );
    if (isSuccess) {
      getSitesList();
    }
  };
  const numberedData = data.map((row, index) => ({
    ...row,
    sequence: index + 1,
  }));

  const filteredData = numberedData.filter((row) => {
    return [
      "id",
      "araId",
      "company",
      "name",
      "street",
      "city",
      "state",
      "zipcode",
      "address",
    ].some((key) =>
      row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPagination = () => {
    const visiblePages = 3;
    const halfVisible = Math.floor(visiblePages / 2);
    let startPage = Math.max(currentPage - halfVisible, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={currentPage === i ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return pages;
  };

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) {
      return "▲▼";
    }
    return sortConfig.direction === "ascending" ? "▲" : "▼";
  };

  const getSitesList = async () => {
    setLoading(true);
    const match = location.pathname.match(/\/site-list\/view\/(\d+)/);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesList`,
      {},
      { companyId: match && match[1] },
      "GET"
    );
    setLoading(false);
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getSitesList();
    }
  }, []);
  if (loading) {
    return <Loader />;
  }

  if (page === "addSite") {
    return <AddSite />;
  } else if (page === "viewRequest") {
    return <ViewRequest />;
  } else if (page === "editSite") {
    return (
      <EditSite
        siteData={selectedSite}
        onSave={handleSaveEdit}
        onCancel={handleCancelEdit}
      />
    );
  }

  return (
    <div className="container mt-1 ">
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            className="form-control"
            onChange={handleEntriesChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-centre  ">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-lg-2 text-end">
          <button
            className="add-site-button btn "
            style={{
              backgroundColor: "#F7921E",
              color: "white",
              fontSize: "12px",
              padding: "10px 22px",
            }}
            onClick={handleAddSite}
          >
            + Add Site
          </button>
        </div>
      </div>

      <div
        className="mt-5"
        style={{ border: "1px solid black", borderRadius: "10px" }}
      >
        <table className="company-table ">
          <thead className="">
            <tr>
              <th style={{ width: "10%" }} onClick={() => sortData("id")}>
                ID {renderSortIcons("id")}
              </th>

              <th style={{ width: "15%" }} onClick={() => sortData("id")}>
                ARA ID {renderSortIcons("id")}
              </th>
              <th style={{ width: "15%" }} onClick={() => sortData("company")}>
                Company {renderSortIcons("company")}
              </th>
              <th style={{ width: "20%" }} onClick={() => sortData("address")}>
                Address {renderSortIcons("address")}
              </th>
              {/* <th style={{ width: '15%' }} onClick={() => sortData('address')}>UpdatedAt {renderSortIcons('address')}</th> */}

              <th style={{ width: "12%" }} onClick={() => sortData("city")}>
                City {renderSortIcons("city")}
              </th>
              <th style={{ width: "10%" }} onClick={() => sortData("state")}>
                State {renderSortIcons("state")}
              </th>
              {/* <th style={{ width: '10%' }} onClick={() => sortData('zipcode')}>Zipcode {renderSortIcons('zipcode')}</th> */}
              {/* <th style={{ width: '14%' }} onClick={() => sortData('status')}>Status {renderSortIcons('status')}</th> */}
              <th style={{ width: "20%" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((row, index) => (
              <tr key={index}>
                <td>{row?.id}</td>
                <td>{row?.araId}</td>
                <td>{row?.company}</td>
                <td>{row?.address}</td>
                {/* <td>{row?.updatedAt}</td> */}

                <td>{row?.city}</td>
                <td>{row?.state}</td>
                {/* <td>{row?.zipcode}</td> */}
                {/* <td><span className={`status ${row.status.toLowerCase().replace(' ', '-')}`}>{row.status}</span></td> */}
                <td className="Action ">
                  <NavLink to={`/view-site`}>
                    <span className="viewIcon me-1">
                      <img src={viewIcon} alt="View Details"  title="view"/>
                    </span>
                  </NavLink>
                  <span
                    className="editIcon me-1 "
                    onClick={() => handleEdit(row)}
                  >
                    <img src={editIcon} alt="" title="edit" />
                  </span>
                  <span
                    onClick={() => handleActionIconClick(row.id, "ARCHIVED")}
                    className="archiveIcon me-1 "
                  >
                    <img src={archiveIcon} alt="" title="archive"/>
                  </span>
                  {/* <span onClick={() => handleActionIconClick(row.id,'DELETE')} className='archiveIcon me-1 '> <img src={deleteIcon} alt='delete' /> </span> */}
                  <NavLink to={`/view-request-site`}>
                    <button
                      className="view-request-button btn"
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#0050A4",
                        color: "white",
                        borderRadius: "8px",
                      }}
                    >
                      View Request
                    </button>
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <ul>
          <li
            onClick={handlePreviousPage}
            className={currentPage === 1 ? "disabled" : ""}
          >
            Previous
          </li>
          {renderPagination()}
          <li
            onClick={handleNextPage}
            className={currentPage === totalPages ? "disabled" : ""}
          >
            Next
          </li>
        </ul>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm {deletedMode === "DELETE" ? "Delete" : "Archive"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {deletedMode === "DELETE" ? "Delete" : "Archive"} this entry?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={handleAction}>
            Yes, {deletedMode === "DELETE" ? "Delete" : "Archive"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Main;
