// import React from "react";
// import { Button, Col, Row } from "reactstrap";

// const PictureForm = () => {
//   return (
//     <div className="form-tab">
//       <Row>
//         <Col sm="12">
//           <h1>Picture Form</h1>
//           <form>
//             <div className="mb-3">
//               <label htmlFor="pictureTitle" className="form-label">
//                 Picture Title
//               </label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="pictureTitle"
//                 placeholder="Enter title"
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="pictureUpload" className="form-label">
//                 Upload Picture
//               </label>
//               <input type="file" className="form-control" id="pictureUpload" />
//             </div>
//             <Button type="submit" color="primary">
//               Submit
//             </Button>
//           </form>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default PictureForm;



import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadIcon from  "../IMg/uploadIcon.png";

const PhotoUploadSection = () => {
  const textStyle = {
    fontSize: '16px',
    color: 'rgba(0, 80, 164, 1)',
  };

  return (
    <div className="container mt-4" style={textStyle}>
      {/* Section 1 */}
      <div className="mb-4">
        <h5 style={{fontSize:'14px'}}>P1. A PHOTO PROPERTY (BUILDING AND/OR LOT) AS SEEN FROM THE STREET</h5>
        <div className=" p-2 " style={{border:' 1px solid #0050A4 '}}>
          <div style={{borderBottom:'1px solid rgba(0, 80, 164, 1)'}}>
          <i style={{backgroundColor:'#0050A4', color:'white',padding:'5px'}} className="bi bi-cloud-upload "></i> <span style={{marginLeft:'20em'}}>Upload The Image </span> 

          </div>
          {/* <button className="btn btn-primary mb-3">
            <i className="bi bi-cloud-upload"></i> Upload The Image
          </button> */}
          <div className="d-flex">
            {[1, 2, 3].map((item) => (
              <div className="position-relative m-2 mt-4" key={item}>
                <img
                  src={uploadIcon}
                  alt="placeholder"
                  className="img-fluid"
                  style={{ background: '#EDF4FC', padding: '20px' }}
                />
                <button
                  className="btn btn-link position-absolute top-0 end-0"
                  style={{ color: 'red', fontSize: '20px' }}
                >
                  {/* &times; */}
                </button>
                <div className="text-center mt-2 me-5" style={{fontSize:'12px'}}>
                  Date: 19/07/2024 Time: 5:49pm
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <input type="checkbox" id="unable1"style={{width:'2%'}} />
            <label htmlFor="unable1" className="ms-2" style={{fontSize:'14px'}}>
              Unable to take photo, please explain
            </label>
          </div>
        </div>
      </div>
  {/* section=2 */}
  <div className="mb-4 " style={{marginTop:'4em'}}>
        <h5 style={{fontSize:'14px'}}> P2. A-PHOTO TAKE A PHOTO OF THE OUTSIDE CLOSED FRONT DOOR TO THE MAIN BUILDING OF THIS SITE PROPERTY, SHOW THE BUSINESS NAME IF POSSIBLE.</h5>
        <div className=" p-2 " style={{border:' 1px solid #0050A4 '}}>
          <div style={{borderBottom:'1px solid rgba(0, 80, 164, 1)'}}>
          <i style={{backgroundColor:'#0050A4', color:'white',padding:'5px'}} className="bi bi-cloud-upload "></i> <span style={{marginLeft:'20em'}}>Upload The Image </span> 

          </div>
          {/* <button className="btn btn-primary mb-3">
            <i className="bi bi-cloud-upload"></i> Upload The Image
          </button> */}
          <div className="d-flex">
            {[1, 2, 3].map((item) => (
              <div className="position-relative m-2 mt-4" key={item}>
                <img
                  src={uploadIcon}
                  alt="placeholder"
                  className="img-fluid"
                  style={{ background: '#EDF4FC', padding: '20px' }}
                />
                <button
                  className="btn btn-link position-absolute top-0 end-0"
                  style={{ color: 'red', fontSize: '20px' }}
                >
                  {/* &times; */}
                </button>
                <div className="text-center mt-2 me-5" style={{fontSize:'12px'}}>
                  Date: 19/07/2024 Time: 5:49pm
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <input type="checkbox" id="unable1"style={{width:'2%'}} />
            <label htmlFor="unable1" className="ms-2" style={{fontSize:'14px'}}>
              Unable to take photo, please explain
            </label>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      {/* <div style={{marginTop:'3em'}} >
        <h5 style={{fontSize:'14px'}}>
        P2. A-PHOTO TAKE A PHOTO OF THE OUTSIDE CLOSED FRONT DOOR TO THE MAIN BUILDING OF THIS SITE PROPERTY, SHOW THE BUSINESS NAME IF POSSIBLE.
        </h5>
        <div className="border p-3">
          <button className="btn btn-primary mb-3">
            <i className="bi bi-cloud-upload"></i> Upload The Image
          </button>
          <div className="d-flex">
            {[1, 2, 3].map((item) => (
              <div className="position-relative m-2" key={item}>
                <img
                  src="https://via.placeholder.com/100"
                  alt="placeholder"
                  className="img-fluid"
                  style={{ background: '#EDF4FC', padding: '20px' }}
                />
                <button
                  className="btn btn-link position-absolute top-0 end-0"
                  style={{ color: 'red', fontSize: '20px' }}
                >
                  &times;
                </button>
                <div className="text-center mt-2">
                  Date: 19/07/2024 Time: 5:49pm
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <input type="checkbox" id="unable2" />
            <label htmlFor="unable2" className="ms-2">
              Unable to take photo, please explain
            </label>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PhotoUploadSection;

