import React from 'react';
import Chart from 'react-apexcharts';
import './RequestsStatus.css';
import { NavLink } from 'react-router-dom';

const RequestsStatus = () => {
  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['New Requests', 'In-Progress Requests', 'To-Be-Approved Requests', 'Approved Inspections'],
    colors: ['#5D8DEC', '#3366CC', '#476195', '#8DADEB'],
    legend: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: '40%'
        }
      }
    }
  };

  const chartSeries = [261, 146, 82, 708];

  return (
    <div className="requests-status">
      <div className="status-header">
        <h2 className="status-title">Requests Status</h2>
        <select className="status-select">
          <option>This Week</option>
        </select>
      </div>
      <div className="status-content">
        <div className="status-chart">
          <Chart options={chartOptions} series={chartSeries} type="donut" width={320} height={360} /> {/* Updated height */}
        </div>
        <div className="status-legend">
  {[
    { label: 'New Requests', link: '/#/new-request' },
    { label: 'In-Progress Requests', link: '/#/in-progress' },
    { label: 'To-Be-Approved Requests', link: '/#/to-be-approved' },
    { label: 'Approved Inspections', link: '/#/approved-Request' }
  ].map((item, index) => (
    <div key={index} className="legend-item">
      <div className="legend-content">
        <div className={`legend-color color-${index + 1}`}></div>
        <a href={item.link} className="legend-label">
          {item.label}
        </a>
        <div className="legend-value">{chartSeries[index]}</div>
      </div>
    </div>
  ))}
</div>

      </div>
    </div>
  );
};

export default RequestsStatus;
