import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import './RevenueGenerated.css';

const RevenueGenerated = () => {
  const [timeframe, setTimeframe] = useState('Month');

  const chartData = {
    Week: {
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      data: [100, 200, 300, 400, 500, 600, 700],
    },
    Month: {
      categories: ['Aug 1', 'Aug 8', 'Aug 15', 'Aug 22', 'Aug 29'],
      data: [500, 600, 700, 800, 900],
    },
    Year: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000],
    },
  };

  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData[timeframe].categories,
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: (value) => `${value}k`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },
    grid: {
      borderColor: '#e7e7e7',
    },
  };

  const chartSeries = [
    {
      name: 'Revenue',
      data: chartData[timeframe].data,
    },
  ];

  return (
    <div className="revenue-generated-container pb-0">
      <div className="revenue-header">
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>Revenue Generated</span>
        <div className="timeframe-buttons">
          <button className={timeframe === 'Week' ? 'active' : ''} onClick={() => setTimeframe('Week')}>Week</button>
          <button className={timeframe === 'Month' ? 'active' : ''} onClick={() => setTimeframe('Month')}>Month</button>
          <button className={timeframe === 'Year' ? 'active' : ''} onClick={() => setTimeframe('Year')}>Year</button>
        </div>
      </div>
      <div className="revenue-summary mt-3">
        <span>Till now <span className="revenue-amount" style={{ fontWeight: 'lighter' }}>$500k</span></span>

        <div className="revenue-month" style={{ color: '#0050A4', border: '1px solid #0050A4' }}>
          <button>{"<"}</button>
          <span>{timeframe === 'Week' ? 'This Week' : timeframe === 'Month' ? 'August 2024' : 'This Year'}</span>
          <button>{">"}</button>
        </div>
      </div>
      <div className="chart-container" style={{ display: 'block', textAlign: 'left', clear: 'both' }}>
        <Chart options={chartOptions} series={chartSeries} type="area" height={250} />
      </div>
    </div>
  );
};

export default RevenueGenerated;
