import React, { useState, useEffect } from 'react';
import './RevenueChart.css';

const RevenueChart = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dropdownValue, setDropdownValue] = useState('July 2024');

  const tableData = {
    week: [
      { date: '28/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company A', revenue: '$1000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company B', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company C', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company D', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company E', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company F', revenue: '$2000' },
    ],
    month: [
      { date: '28/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company G', revenue: '$3000' },
      { date: '25/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company I', revenue: '$4000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company J', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company K', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company L', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company N', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company O', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company P', revenue: '$2000' },
    ],
    year: [
      { date: '28/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company Q', revenue: '$5000' },
      { date: '01/01/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company R', revenue: '$6000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company S', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company T', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company U', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company V', revenue: '$2000' },
      { date: '27/07/24 10:57:46', abaId: 'WAXYmpNk', companyName: 'Company W', revenue: '$2000' },
    ],
  };

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '98%',
      backgroundColor: 'white',
      margin: '0 auto',
      padding: '20px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    dateInputs: {
      display: 'flex',
      gap: '10px',
    },
    dateInputGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: '5px',
      fontSize: '14px',
    },
    input: {
      padding: '5px',
      border: '1px solid #0050A4',
      borderRadius: '4px',
      width: '110px',
      color: '#0050A4',
    },
    select: {
      padding: '15px',
      border: '1px solid #0050A4',
      borderRadius: '4px',
      marginTop: '5px',
      color: '#0050A4',
    },
    buttons: {
      display: 'flex',
    },
    button: {
      padding: '8px 16px',
      backgroundColor: '#0050A4',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginLeft: '5px',
    },
    activeButton: {
      backgroundColor: 'white',
      color: '#0050A4',
      border: '1px solid #0050A4',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    th: {
      backgroundColor: '#DFE5F9',
      textAlign: 'left',
      padding: '8px',
      fontSize:'14px',
      color:'#1F263E',
      borderBottom: '1px solid #ddd',
    },
    td: {
      padding: '8px',
      fontSize:'14px',
      color: '#1F263E',
      textAlign:'left',
      borderBottom: '1px solid #ddd',
    },
    tdata: {
      color: '#0043FF',
      fontSize:'14px',
      textAlign: 'right',
      borderBottom: '1px solid #ddd',
    },
    tdate: {
      color: '#1F263E',
      fontSize:'14px',
      borderBottom: '1px solid #ddd',
    },
    the: {
      backgroundColor: '#DFE5F9',
      textAlign: 'right',
      padding: '12px',
      borderBottom: '1px solid #ddd',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      gap: '10px',
    },
    pageButton: {
      padding: '5px 10px',
      border: '1px solid #0066cc',
      backgroundColor: 'white',
      color: '#0066cc',
      cursor: 'pointer',
    },
    activePageButton: {
      backgroundColor: '#0066cc',
      color: 'white',
    },
  };

  useEffect(() => {
    if (selectedPeriod === 'week') {
      setDropdownValue('Weekly');
    } else if (selectedPeriod === 'month') {
      setDropdownValue('July 2024');
    } else if (selectedPeriod === 'year') {
      setDropdownValue('Yearly');
    }
  }, [selectedPeriod]);

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const handleDateChange = (e, type) => {
    if (type === 'from') setFromDate(e.target.value);
    if (type === 'to') setToDate(e.target.value);
  };

  return (
    <div style={styles.container} className='revenue-table-head mt-4'>
      <div style={styles.header}>
        <h2 style={{ color: 'black', fontSize: '16px', fontWeight: 'bold', }} className='mt-2'>Revenue Forecast</h2>
        <div style={styles.dateInputs}>
          <div style={styles.dateInputGroup} className='me-4'>
            <label style={styles.label}>From Date</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => handleDateChange(e, 'from')}
              style={styles.input}
            />
          </div>
          <div style={styles.dateInputGroup}>
            <label style={styles.label}>To Date</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => handleDateChange(e, 'to')}
              style={styles.input}
            />
          </div>
        </div>
        <select value={dropdownValue} style={styles.select} className='RevenueTable-select'>
          <option>{dropdownValue}</option>
        </select>
        <div style={styles.buttons}>
          <button
            style={selectedPeriod === 'week' ? { ...styles.button, ...styles.activeButton } : styles.button}
            onClick={() => handlePeriodChange('week')}
          >
            Week
          </button>
          <button
            style={selectedPeriod === 'month' ? { ...styles.button, ...styles.activeButton } : styles.button}
            onClick={() => handlePeriodChange('month')}
          >
            Month
          </button>
          <button
            style={selectedPeriod === 'year' ? { ...styles.button, ...styles.activeButton } : styles.button}
            onClick={() => handlePeriodChange('year')}
          >
            Year
          </button>
        </div>
      </div>
      <table style={styles.table}>
        <colgroup>
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '42%' }} />
          <col style={{ width: '18%' }} />
        </colgroup>
        <thead>
          <tr>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>ARA ID</th>
            <th style={styles.th}>Company Name</th>
            <th style={styles.the}>Revenue Generated</th>
          </tr>
        </thead>
        <tbody>
          {tableData[selectedPeriod].map((row, index) => (
            <tr key={index}>
              <td style={styles.tdate}>{row.date}</td>
              <td style={styles.td}>{row.abaId}</td>
              <td style={styles.td}>{row.companyName}</td>
              <td style={styles.tdata}>{row.revenue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RevenueChart;
