import React from "react";
import "./SiteContact.css";
import deleteIcon from "../img/deleteIcon.png";
import exportIcon from "../img/exportIcon.png";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
function SiteContact({ answer, setAnswer, onSwitchToDetail }) {
  const navigate = useNavigate();
  const changeFname = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        fname: e.target.value,
      },
    }));
  };
  const changeLname = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        lname: e.target.value,
      },
    }));
  };

  const changeEmail = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        email: e.target.value,
      },
    }));
  };
  const changeDesignation = (e) => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      userData: {
        ...prevAnswer.userData,
        designation: e.target.value,
      },
    }));
  };

  const addNewSitesAndContact = async (e) => {
    e.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewSitesAndContact`,
      {},
      { ...answer },
      "POST"
    );
    if (isSuccess) {
      // setCaseList(data?.data?.reverse());
      navigate("/site-list");
    }
  };

  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Site</h2>
        <button className="export-btn">
          <img className="me-1 " src={exportIcon} alt=" " />
          Export
        </button>
      </div>
      <div className="tabs">
        <button className="tab" onClick={onSwitchToDetail}>
          Site Detail
        </button>
        <button className="tab active">Site Contact</button>
      </div>
      <div className="site-detail-h">
        <div className="site-detail-left">Site contact</div>
        <div className="site-detail-right">
          <button className="me-4 ps-2 pe-2">+ Add </button>
          <img src={deleteIcon} alt="" />{" "}
        </div>
      </div>
      <div className="site-contact">
        <form onSubmit={(e) => addNewSitesAndContact(e)}>
          <div className="form-row">
            <div className="form-group">
              <span htmlFor="address">First Name</span>
              <input onClick={changeFname} type="text" id="address" />
            </div>
            <div className="form-group">
              <span htmlFor="address-2">Last Name</span>
              <input onChange={changeLname} type="text" id="address-2" />
            </div>
            <div className="form-group">
              <span htmlFor="state">Email</span>
              <input onChange={changeEmail} type="text" id="state" />
            </div>
          </div>
          <div className="form-row ">
            <div className="form-group row ">
              <div className="form-group-col col-lg-5  dropdown-container">
                <span htmlFor="ara-id">Designation</span>
                <select 
                onChange={changeDesignation}
                className="form-control col-lg-6" 
                value={answer.designation}
                
                >
                  <option value="">Select Designation</option>
                  <option value="Site Admin">Site Admin</option>
                  <option value="Site Manager">Site Manager</option>
                </select>
              </div>
            </div>
          </div>

          <div className="form-group-foot">
            <span className="text-start">
              <button
                type="submit"
                className="previous-btn"
                onClick={onSwitchToDetail}
              >
                Previous
              </button>
            </span>
            <span className="text-end">
              <button type="submit" className="next-btn ps-4 pe-4">
                + SAVE
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SiteContact;
