import React, { useEffect, useState } from 'react';
import exportIcon from '../img/exportIcon.png';
import { apiCall } from '../../../services/ApiCall';
import config from "../../../config/config.json";
import { useNavigate } from 'react-router-dom';

const SiteHistory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});


  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const getSitesHistoryList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesHistoryList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getSitesHistoryList();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h2 style={{ fontSize: '32px', color: 'rgba(0, 80, 164, 1)' }}>Site History</h2>
        <button style={{ fontSize: '16px', padding: '8px 15px', backgroundColor: 'white', border: '1px solid rgba(0, 80, 164, 1)', color: 'rgba(0, 80, 164, 1)', borderRadius: '5px', cursor: 'pointer' }}>
          <img src={exportIcon} alt='' /> Export
        </button>
      </div>
      <div style={{ borderRadius: '10px', width: '100%', boxSizing: 'border-box', marginTop: '4em' }}>
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white' }}>
            <thead>
              <tr>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Company</th>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>ARA ID</th>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Address</th>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Activity By</th>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Activity</th>
                <th style={{ width: '20%', padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Date & Time</th>
                {/* <th style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: 'white', backgroundColor: '#0050A4', fontWeight: 'bold', position: 'sticky', top: '0' }}>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} style={{ backgroundColor: 'white', borderBottom: '1px solid #ccc' }}>
                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="company"
                        value={editData.company}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item?.companyName
                    )}
                  </td>
                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="araId"
                        value={editData.araId}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item.araId
                    )}
                  </td>
                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activityBy"
                        value={editData.activityBy}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item?.name
                    )}
                  </td>

                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activityBy"
                        value={editData.activityBy}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item?.address
                    )}
                  </td>

                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="activity"
                        value={editData.activity}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item?.activity
                    )}
                  </td>
                  <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        name="dateTime"
                        value={editData.dateTime}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      item?.createdAt
                    )}
                  </td>
                  {/* <td style={{ padding: '12px 15px', textAlign: 'left', fontSize: '14px', color: '#0050A4' }}>
                    {editIndex === index ? (
                      <button className=' btn btn-primary' onClick={handleSaveClick} style={{ backgroundColor: '#0050A4', border: 'none', cursor: 'pointer' }}>
                        Save
                      </button>
                    ) : (
                      <img
                        src={editIcon}
                        alt='edit icon'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEditClick(index)}
                      />
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SiteHistory;
