import React from 'react';
import Chart from 'react-apexcharts';
import './CompaniesOverview.css';
import numberofCompanyIcon from './img/numberofCompanyIcon.png';
import numberofLocation from './img/numberofLocationIcon.png';

const CompaniesOverview = () => {
  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      sparkline: { enabled: true },
    },
    stroke: { curve: 'smooth', width: 2 },
    colors: ['#4e79a7'],
    tooltip: { enabled: false },
    grid: { show: false },
    xaxis: { labels: { show: false }, axisBorder: { show: false }, axisTicks: { show: false } },
    yaxis: { labels: { show: false } },
  };

  const companiesChartSeries = [{ data: [10, 12, 15, 14, 16] }];
  const sitesChartSeries = [{ data: [15, 17, 19, 18, 20] }];

  return (
    <div className="companies-overview">
      <h2 className="overview-title mb-4">Companies & Sites Overview</h2>
      <div className="overview-content">
        <div className="overview-row">
          
          <div className="overview-card">
            <div className="card-icon"><img src={numberofCompanyIcon}/></div>
            <div className="card-info">
              <span className="card-value">70</span>
              <span className="card-label">Total Number Of Companies</span>
            </div>
          </div>
          <div className="overview-chart">
  <div className="chart-content">
    <div className="chart-info">
      <span className="chart-title" style={{fontSize:'16px'}}>Companies Addition Rate</span>
      <div className="chart-value" >
        <span className="value"style={{fontSize:'24px'}}>16</span>
        <div className="sub-value" style={{fontSize:'14px'}}>Out of 25</div>
      </div>
    </div>
    <div className="chart-container" style={{width:'45%'}}>
      <span className="chart-date">&lt; July, 2024 &gt;</span>
      <Chart options={chartOptions} series={companiesChartSeries} type="line" height={60} />
    </div>
  </div>
</div>


        </div>
        <div className="overview-row">
          <div className="overview-card">
            <div className="card-icon"><img src={numberofLocation}/> </div>
            <div className="card-info">
              <span className="card-value">150</span>
              <span className="card-label">Total Number Of Sites</span>
            </div>
          </div>
         


  <div className="overview-chart">
  <div className="chart-content">
    <div className="chart-info">
      <span className="chart-title" style={{fontSize:'16px'}}>Sites Addition Rate</span>
      <div className="chart-value">
        <span className="value"style={{fontSize:'24px'}}>20</span>
        <div className="sub-value"style={{fontSize:'15px'}}>Out of 50</div>
      </div>
    </div>
    <div className="chart-container" style={{width:'45%'}}>
      <span className="chart-date">&lt; July, 2024 &gt;</span>
      <Chart options={chartOptions} series={companiesChartSeries} type="line" height={50} />
    </div>
  </div>
</div>


        </div>
      </div>
    </div>
  );
};

export default CompaniesOverview;
