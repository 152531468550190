import React, { useState } from "react";
import NavTabs from "./components/NavTabs";
import { Button } from "reactstrap";

import editImg from "../../assets/images/icons/edit-icon.png";
import PictureForm from "./components/PictureForm";

const TabForm = () => {
  const [activeForm, setActiveForm] = useState("DATA_FORM");

  const renderForm = () => {
    if (activeForm === "DATA_FORM") {
      return <NavTabs />;
    } else if (activeForm === "PICTURE_FORM") {
      return <PictureForm />
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between mb-4">
        <div className="">
          <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'DATA_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("DATA_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            DATA FORM
          </Button>
          <Button
            className={`px-5 me-5 add-form-button ${activeForm === 'PICTURE_FORM' ? 'active' : ''}`}
            onClick={() => setActiveForm("PICTURE_FORM")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            PICTURE FORM
          </Button>
        </div>
        <Button className="edit-form-btn">
          <img src={editImg} alt="Edit" />
        </Button>
      </div>
      {renderForm()}
    </div>
  );
};

export default TabForm;
