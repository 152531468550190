import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import './WeeklyStatusChart.css';

const WeeklyStatusChart = () => {
  const [activeTab, setActiveTab] = useState('thisWeek');
  const [activeSeries, setActiveSeries] = useState('both');

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      stacked: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 10
    },
    colors: ['#0050A4', 'rgba(11, 116, 78, 0.4)'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
        borderRadius: 8,
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      labels: {
        style: {
          colors: ['#333'],
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#333'],
          fontSize: '12px',
        },
      },
    },
    legend: {
      show: false, // Hide default legend as we are creating a custom one
    },
    grid: {
      borderColor: '#e0e0e0',
    },
    fill: {
      opacity: 1,
    },
  };

  const thisWeekSeries = [
    {
      name: 'Companies',
      data: [28, 22, 24, 28, 10, 26, 25],
    },
    {
      name: 'Sites',
      data: [20, 14, 10, 18, 5, 15, 20],
    },
  ];

  const lastWeekSeries = [
    {
      name: 'Companies',
      data: [15, 18, 20, 22, 12, 19, 17],
    },
    {
      name: 'Sites',
      data: [10, 8, 12, 14, 7, 10, 13],
    },
  ];

  const filteredSeries =
    activeSeries === 'both'
      ? activeTab === 'thisWeek'
        ? thisWeekSeries
        : lastWeekSeries
      : activeTab === 'thisWeek'
      ? thisWeekSeries.filter((series) => series.name.toLowerCase() === activeSeries)
      : lastWeekSeries.filter((series) => series.name.toLowerCase() === activeSeries);

  return (
    <div className="weekly-status-chart" style={{backgroundColor:'white',borderRadius:'20px',padding:'40px' }}>
      <div style={{color:'rgba(52, 60, 106, 1)',fontWeight:'600',fontSize:'18px',marginBottom:'1em',marginTop:'0.2em'}}>Weekly Status Of Companies & Sites Added</div>
      <div className="chart-header">
        <div className="chart-tabs">
          <span
            className={activeTab === 'thisWeek' ? 'active-tab' : ''}
            onClick={() => setActiveTab('thisWeek')}
          >
            This Week
          </span>
          <span
            className={activeTab === 'lastWeek' ? 'active-tab' : ''}
            onClick={() => setActiveTab('lastWeek')}
          >
            Last Week
          </span>
        </div>
        <div className="chart-series-tabs">
          <span
            className={activeSeries === 'companies' ? 'active-tab' : ''}
            onClick={() => setActiveSeries('companies')}
          >
            Companies
          </span>
          <span
            className={activeSeries === 'sites' ? 'active-tab' : ''}
            onClick={() => setActiveSeries('sites')}
          >
            Sites
          </span>
        </div>
      </div>
      <Chart
        options={chartOptions}
        series={filteredSeries}
        type="bar"
        height={300}
        width="100%"
      />
    </div>
  );
};

export default WeeklyStatusChart;
