import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './styles.css';

const detailedData = [
  { id: '1000', araId: 'WAYvmqNk', company: 'Ghrix', site: '8203 San Marino Drive San Antonio TX 78250', amount: 5000, date: '09/28/2023' },
  { id: '1001', araId: 'WAYvmqNk', company: '1st Choice Recovery LLC', site: '8203 San Marino Drive San Antonio TX 78250', amount: 2300, date: '09/28/2023' },
  { id: '1002', araId: 'WAYvmqNk', company: '59 Second Recovery LLC', site: '8203 San Marino Drive San Antonio TX 78250', amount: 7000, date: '09/28/2023' },
  { id: '1003', araId: 'WAYvmqNk', company: 'A & A Towing and Recovery MO', site: '8203 San Marino Drive San Antonio TX 78250', amount: 1500, date: '09/28/2023' },
  { id: '1004', araId: 'WAYvmqNk', company: 'A Landers Recovery Service', site: '8203 San Marino Drive San Antonio TX 78250', amount: 3400, date: '09/28/2023' },
  { id: '1005', araId: 'WAYvmqNk', company: 'A To Z Recovery LLC', site: '8203 San Marino Drive San Antonio TX 78250', amount: 700, date: '09/28/2023' },
  { id: '1006', araId: 'WAYvmqNk', company: 'A+ Towing & Recovery', site: '8203 San Marino Drive San Antonio TX 78250', amount: 8000, date: '09/28/2023' },
  { id: '1007', araId: 'WAYvmqNk', company: 'A-1 Repossessions', site: '8203 San Marino Drive San Antonio TX 78250', amount: 1800, date: '09/28/2023' },
];

const InvoiceDetails = () => {
  const { id } = useParams();
  const invoice = detailedData.find(item => item.id === id);

  if (!invoice) {
    return <div>Invoice not found {id}</div>;
  }

  return (
    <div className="container" >
      <h2>Invoice Details</h2>
      <table className="invoice-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>ARA ID</th>
            <th>Company</th>
            <th>Site</th>
            <th>Amount</th>
            <th>Invoice Created Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{invoice.id}</td>
            <td>{invoice.araId}</td>
            <td>{invoice.company}</td>
            <td>{invoice.site}</td>
            <td>${invoice.amount}</td>
            <td>{invoice.date}</td>
            <td>
              <button className="details-btn">Details</button>
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/" className="back-btn">Back to Invoice List</Link>
    </div>
  );
};

export default InvoiceDetails;