import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './InvoiceDetails';
import './styles.css';
import icon from './img/filtericon.png';
const initialData = [
  { id: '553602000001823002', quantity: 10, amount: 5000, date: '09/28/2023' },
  { id: '553602000018230002', quantity: 8, amount: 2300, date: '09/28/2023' },
  { id: '553602000018350002', quantity: 18, amount: 7000, date: '09/28/2023' },
  { id: '553602000018250002', quantity: 20, amount: 1500, date: '09/28/2023' },
  { id: '553602000018230002', quantity: 15, amount: 3400, date: '09/28/2023' },
  { id: '553602000018230002', quantity: 4, amount: 700, date: '09/28/2023' },
  { id: '553602000018230003', quantity: 25, amount: 8000, date: '09/28/2023' },
  { id: '553602000018230003', quantity: 7, amount: 1800, date: '09/28/2023' },
  { id: '553602000018230003', quantity: 7, amount: 1800, date: '09/28/2023' },
  { id: '553602000018230003', quantity: 9, amount: 1800, date: '09/28/2023' },


];

const InvoiceTable = () => {
  const [data] = useState(initialData);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = data.filter(item =>
    Object.values(item).some(val => 
      val.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
   <>
    <div className="container mt-0 ">
      <div className="controls">
        <div className="entries-control">
          Show 
          <select 
            value={itemsPerPage} 
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
          >
               <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          entries
        </div>
        <div className="search-control">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <span><img src={icon} alt='icon' /> </span>
      </div>

      <div className='invoiceTableBoxing mt-5' style={{fontSize:'14px'}}>
      <table className="inspections-table " >

    
  <thead className="thead">
          <tr>
            <th style={{width:'25%'}}>Zoho Invoice ID</th>
            <th style={{width:'20%'}}>Quantity</th>
            <th style={{width:'20%'}}>Amount</th>
            <th style={{width:'25%'}}>Invoice Created Date</th>
            <th style={{width:'10%'}}>Action</th>
          </tr>
        </thead>
        
        <tbody>
  
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.quantity}</td>
              <td>${item.amount}</td>
              <td>{item.date}</td>
              <td>
                <Link to={`/invoice/${item.id}`} className="details-btn">
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
        
      </table>
      </div>
      <div className="pagination">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
        {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
          <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
            {i + 1}
          </button>
        ))}
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}>Next</button>
      </div>
    </div>
   </>
  );
};

export default InvoiceTable;