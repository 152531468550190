import React, { useState } from "react";
import Chart from "react-apexcharts";

const RevenueForecastChart = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("Year");
  const [selectedYear, setSelectedYear] = useState("2024 - 2025");

  const data = {
    Week: [15000, 20000, 30000, 43099, 25000, 35000, 28000],
    Month: [15040, 25050, 30508, 45309, 25900, 30500, 26800, 36900, 37600, 34000, 37000, 20000],
    Year: [150000, 200000, 300000, 430999, 250000, 350000, 280000, 390000, 360000, 340000, 300000, 200000],
  };

  const categories = {
    Week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    Month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    Year: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  };

  const series = [
    {
      name: "Revenue",
      data: data[selectedPeriod],
    },
  ];

  const options = {
    chart: {
      id: "revenue-forecast",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories[selectedPeriod],
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value / 1000}k`,
      },
      min: 0,
      max: selectedPeriod === "Year" ? 500000 : selectedPeriod === "Month" ? 100000 : 50000,
      tickAmount: 5,
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toLocaleString()}`,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["rgba(200, 220, 240, 1)"],  // Darker border color
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        colorStops: [
          {
            offset: 0,
            color: "rgba(237, 244, 252, 1)",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(237, 244, 252, 1)",
            opacity: 0.2,
          },
        ],
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 4,
    },
    colors: ["rgba(237, 244, 252, 1)"],
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    // Here, you could update the data based on the selected year if needed
  };

  return (
    <div className="mt-4">
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "#0050A4",
            padding: "10px 20px",
            borderRadius: "10px 10px 0 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
          }}
        >
          <div style={{ flex: 1 }}>
            <h2 style={{ margin: 0, color: "#FEFEFE", fontSize: "22px" }}>Revenue Forecast</h2>
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <select
              value={selectedYear}
              onChange={handleYearChange}
              style={{
                padding: "5px 10px",
                backgroundColor: "white",
                border: "none",
                borderRadius: "5px",
                color: "#0050A4",
              }}
              className="RevenueForcastBtn"
            >
              {Array.from({ length: 11 }, (_, i) => 2020 + i).map((year) => (
                <option key={year} value={`${year} - ${year + 1}`}>
                  {`${year} - ${year + 1}`}
                </option>
              ))}
            </select>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            {["Week", "Month", "Year"].map((period) => (
              <button
                key={period}
                style={{
                  marginRight: "10px",
                  padding: "5px 10px",
                  backgroundColor: selectedPeriod === period ? "white" : "transparent",
                  border: "none",
                  borderRadius: "5px",
                  color: selectedPeriod === period ? "#0050A4" : "white",
                }}
                onClick={() => setSelectedPeriod(period)}
              >
                {period}
              </button>
            ))}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "White",
            padding: "20px",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Chart options={options} series={series} type="area" height={350} />
        </div>
      </div>
    </div>
  );
};

export default RevenueForecastChart;
