import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";

const tabItems = [
  "Company Information",
  "Information",
  "Exterior",
  "Facility & Business Operations",
  "Employee Training",
  "Office",
  "Personal Property",
  "Vehicle Storage",
  "General",
];

const NavTabs = () => {
  const [activeTab, setActiveTab] = useState("0");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const nextTab = () => {
    const nextIndex = parseInt(activeTab) + 1;
    if (nextIndex < tabItems.length) {
      setActiveTab(nextIndex.toString());
    }
  };

  const previousTab = () => {
    const prevIndex = parseInt(activeTab) - 1;
    if (prevIndex >= 0) {
      setActiveTab(prevIndex.toString());
    }
  };

  const renderFormContent = (tabName) => {
    switch (tabName) {
      case "Company Information":
        return (
          <Form>
            <FormGroup>
              <Label for="companyName" style={{color:'#0050A4'}}>Company Name</Label>
              <Input
                color="#0050A4"
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Enter company name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="companyAddress"style={{color:'#0050A4'}}>Address</Label>
              <Input
                type="text"
                name="companyAddress"                
                id="companyAddress"
                placeholder="Enter address"
                style={{ lineHeight: '5' }}
              />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Information":
        return (
          <Form >
            <FormGroup >
              {/* <Label for="infoDetails">Information Details</Label> */}
              <p  style={{fontSize:'10px', color:'#0050A4'}}>PLEASE ANSWER ALL OF THE 108 QUESTIONS. IF ANY OF YOUR ANSWERS NEED FURTHER INFORMATION, A TEXT BOX WILL POP UP; PLEASE PUT IN THE APPROPRIATE INFORMATION REQUIRED. DO NOT CLOSE THE BROWSER BEFORE CLICKING THE "SAVE" BUTTON AT THE BOTTOM OF THIS SCREEN WHEN YOU HAVE ANSWERED ALL THE QUESTIONS.</p>
              {/* <Input
                type="textarea"
                name="infoDetails"
                id="infoDetails"
                placeholder="Enter information details"
              /> */}
               <Label for="trainingDate" style={{color:'#0050A4'}}> Date</Label>
               <Input type="date" style={{width:'300px',color:'#0050A4'}} name="trainingDate" id="trainingDate"  />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Exterior":
        return (
          <Form>
            <FormGroup>
              <p style={{color:'#0050A4'}}>D1. C-1 WHAT TYPE OF ESTABLISHMENT IS COMPANY LOCATED IN?</p>
              {/* <Label for="exteriorDescription">Exterior Description</Label>
              <Input
                type="text"
                name="exteriorDescription"
                id="exteriorDescription"
                placeholder="Describe exterior"
              /> */}
            </FormGroup>
            <FormGroup>
              {/* <Label for="exteriorImage">Upload Image</Label>
              <Input type="file" name="exteriorImage" id="exteriorImage" /> */}
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      // Add other forms for the remaining tabs
      case "Facility & Business Operations":
        return (
          <Form>
            <FormGroup>
              <p style={{color:'#0050A4'}}>D4. C-4 IS THIS FACILITY RENTED/LEASED OR OWNED?</p>
              {/* <Label for="operationHours">Operation Hours</Label>
              <Input
                type="text"
                name="operationHours"
                id="operationHours"
                placeholder="Enter operation hours"
              /> */}
            </FormGroup>
            {/* <FormGroup>
              <Label for="businessLicense">Business License Number</Label>
              <Input
                type="text"
                name="businessLicense"
                id="businessLicense"
                placeholder="Enter license number"
              />
            </FormGroup> */}
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Employee Training":
        return (
          <Form>
            <p style={{color:'#0050A4'}}> D34. C-18 ARE BACKGROUND CHECKS/SEARCHES CONDUCTED ON NEW EMPLOYEES AND/OR DRIVERS (OR CUSTOMER-FACING EMPLOYEES)? CHECK ALL THAT APPLY</p>
            {/* <FormGroup>

              <Label for="trainingProgram">Training Program</Label>
              <Input
                type="text"
                name="trainingProgram"
                id="trainingProgram"
                placeholder="Enter training program"
              />
            </FormGroup>
            <FormGroup>
              <Label for="trainingDate">Training Date</Label>
              <Input type="date" name="trainingDate" id="trainingDate" />
            </FormGroup> */}
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Office":
        return (
          <Form>
            <FormGroup>
              <Label for="officeLocation">Office Location</Label>
              <Input
                type="text"
                name="officeLocation"
                id="officeLocation"
                placeholder="Enter office location"
              />
            </FormGroup>
            <FormGroup>
              <Label for="officeSize">Office Size</Label>
              <Input
                type="text"
                name="officeSize"
                id="officeSize"
                placeholder="Enter office size"
              />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Personal Property":
        return (
          <Form>
            <FormGroup>
              <Label for="propertyDescription">Property Description</Label>
              <Input
                type="text"
                name="propertyDescription"
                id="propertyDescription"
                placeholder="Describe property"
              />
            </FormGroup>
            <FormGroup>
              <Label for="propertyValue">Property Value</Label>
              <Input
                type="text"
                name="propertyValue"
                id="propertyValue"
                placeholder="Enter property value"
              />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "Vehicle Storage":
        return (
          <Form>
            <FormGroup>
              <Label for="vehicleType">Vehicle Type</Label>
              <Input
                type="text"
                name="vehicleType"
                id="vehicleType"
                placeholder="Enter vehicle type"
              />
            </FormGroup>
            <FormGroup>
              <Label for="storageLocation">Storage Location</Label>
              <Input
                type="text"
                name="storageLocation"
                id="storageLocation"
                placeholder="Enter storage location"
              />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      case "General":
        return (
          <Form>
            <FormGroup>
              <Label for="generalComments">General Comments</Label>
              <Input
                type="textarea"
                name="generalComments"
                id="generalComments"
                placeholder="Enter any general comments"
              />
            </FormGroup>
            {/* <Button type="submit" color="primary">
              Submit
            </Button> */}
          </Form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-tab">
      <Row>
        <Col sm="2">
          <Nav pills vertical>
            {tabItems.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === `${index}` })}
                  onClick={() => {
                    toggle(`${index}`);
                  }}
                >
                  {item}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col sm="10">
          <TabContent activeTab={activeTab} >
            {tabItems.map((item, index) => (
              <TabPane tabId={`${index}`} key={index}>
                <Row>
                  <Col sm="12">
                    <h1 className="">{item}</h1>
                    {renderFormContent(item)}

                    <div className="d-flex justify-content-between mt-4">
                      {index > 0 && (
                        <Button
                          className="next-prev-btn px-5 mt-5"
                          onClick={previousTab}
                        >
                          Previous
                        </Button>
                      )}
                      {index < tabItems.length - 1 && (
                        <Button
                          className="next-prev-btn px-5 mt-5"
                          onClick={nextTab}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default NavTabs;
