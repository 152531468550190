import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const RequestStatusChart = () => {
  const [selectedCategory, setSelectedCategory] = useState('New Request');
  const [selectedYear, setSelectedYear] = useState('2023-2024');

  const data = {
    'New Request': [200, 300, 500, 700, 600, 400, 500, 300, 500, 700, 900, 200],
    'In-Progress Request': [150, 250, 450, 650, 550, 350, 450, 250, 450, 650, 850, 950],
    'To-Be-Approved Request': [100, 200, 400, 600, 500, 300, 400, 200, 400, 600, 800, 900],
    'Approved Request': [250, 350, 550, 750, 650, 450, 550, 350, 550, 750, 950, 1050],
  };

  const options = {
    chart: {
      type: 'bar',
      height: 10,
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        horizontal: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 10000, // This range covers all values
              color: '#0050A4', // Set bar color
            },
          ],
        },
      },
    },
    xaxis: {
      categories: [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
        'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC',
      ],
    },
    stroke: {
      colors: ['transparent'],
      width: 15,
    },
    fill: {
      opacityFrom: 0.4,
      opacityTo: 0.5,
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
  };

  const series = [
    {
      name: selectedCategory,
      data: data[selectedCategory],
    },
  ];

  return (
    <div style={{ backgroundColor: 'white', padding: '20px',borderRadius:'12px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ color: 'black', fontSize: '24px', fontWeight:'bold' }}>Requests Status</h2>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={{ padding: '1px', fontSize: '16px',color:'#0050A4' }}
        >
          <option value="2023-2024">2023-2024</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2021-2022">2021-2022</option>
        </select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '40px', marginBottom: '20px', fontSize: '12px' }}>
        {['New Request', 'In-Progress Request', 'To-Be-Approved Request', 'Approved Request'].map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            style={{
              color: selectedCategory === category ? '#002A7E' : 'rgba(71, 97, 149, 0.5)',
              border: 'none',
              fontWeight:'bold',
              backgroundColor:'white',
              cursor: 'pointer',
            }}
          >
            {category}
          </button>
        ))}
      </div>
      <Chart options={options} series={series} type="bar" height={205} />
    </div>
  );
};

export default RequestStatusChart;
