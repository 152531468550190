import React, { useState } from 'react';
import ProfileLogo from "./img/ExteriorLogo.png";
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const ExteriorForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  // CSS as a function
  const styles = {
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e7f3ff',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '10px'
    },
    h2: {
      margin: 0,
      fontSize: '24px',
      color:'#0050A4',
      fontWeight:'600',
    },
    toggleButton: {
      backgroundColor: 'transparent',
      border: '1px solid #0050A4',
    //   padding:'4px',
      borderRadius:'8px',
      fontSize: '18px',
      cursor: 'pointer',

    },
    formContent: {
    //   backgroundColor: '#f9f9f9',
      padding: '35px',
      borderRadius: '10px',
      border: '1px solid rgba(0, 80, 164, 0.5)',
      color:'rgba(0, 80, 164, 0.5)',
    },
    formGroup: {
        // padding:'20px',
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '16px'
    },
  
    inputRadioCheckbox: {
        width:'5%',
      marginRight: '8px',

    },
    photoUpload: {
      display: 'flex',
      gap: '20px'
    },
    photoUploadImg: {
    //   width: '100px',
      backgroundColor:'rgba(191, 207, 223, 0.4)',

    //   height: '100px',
      padding:'10px',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  };

  return (
    <div>
      {/* Button to toggle the form */}
      <div style={styles.formHeader}>
        <span style={styles.h2}>Exterior</span>
        <button  onClick={toggleForm} style={styles.toggleButton}>
          {isOpen ?  <i class="fa-solid fa-minus"></i>: <i class="fa-solid fa-plus"></i> }
        </button>
      </div>

      {/* Conditional rendering of the form */}
      {isOpen && (
        <div style={styles.formContent}>
          <div style={styles.formGroup}>
            <label style={styles.label}>P1. A PHOTO Property (building and/or lot) IMPORTANT take picture standing across the street.</label>
            <div style={styles.photoUpload}>
              <img src={ProfileLogo} alt="Upload 1" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 2" style={styles.photoUploadImg} />
              <img src={ProfileLogo} alt="Upload 3" style={styles.photoUploadImg} />
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D1. What type of establishment is Company located in? </label>
            <div  >
            
              <input type="radio" name="establishment" id="dedicated" style={styles.inputRadioCheckbox} />
              
              <label htmlFor="dedicated">Dedicated Commercial Building (single business office building)</label> <br/>

              <input type="radio" name="establishment" id="multi-tenant" style={styles.inputRadioCheckbox} />
              <label htmlFor="multi-tenant">Multi-Tenant Building (more than one business in an office building)</label><br />

              <input type="radio" name="establishment" id="office-trailer" style={styles.inputRadioCheckbox} />
              <label htmlFor="office-trailer">Office Trailer</label><br />

              <input type="radio" name="establishment" id="residential" style={styles.inputRadioCheckbox} />
              <label htmlFor="residential">Private Residence or Residential Apartment</label><br />

              <input type="radio" name="establishment" id="mobile-home" style={styles.inputRadioCheckbox} />
              <label htmlFor="mobile-home">Mobile Home</label><br />

              <input type="radio" name="establishment" id="other-establishment" style={styles.inputRadioCheckbox} />
              <label htmlFor="other-establishment">Other. Please explain:</label>
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D2. Describe the structure</label>
            <div>
              <input type="checkbox" id="brick" style={styles.inputRadioCheckbox} />
              <label htmlFor="brick">Brick/masonry</label><br />

              <input type="checkbox" id="wood-frame" style={styles.inputRadioCheckbox} />
              <label htmlFor="wood-frame">Wood frame</label><br />

              <input type="checkbox" id="metal" style={styles.inputRadioCheckbox} />
              <label htmlFor="metal">Metal</label><br />

              <input type="checkbox" id="other-structure" style={styles.inputRadioCheckbox} />
              <label htmlFor="other-structure">Other. Please explain:</label>
            </div>
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>D3. In what type of area is the facility located?</label>
            <div>
              <input type="checkbox" id="commercial" style={styles.inputRadioCheckbox} />
              <label htmlFor="commercial">Commercial</label><br />

              <input type="checkbox" id="industrial" style={styles.inputRadioCheckbox} />
              <label htmlFor="industrial">Industrial</label><br />

              <input type="checkbox" id="residential-area" style={styles.inputRadioCheckbox} />
              <label htmlFor="residential-area">Residential</label><br />

              <input type="checkbox" id="rural" style={styles.inputRadioCheckbox} />
              <label htmlFor="rural">Rural</label><br />

              <input type="checkbox" id="other-area" style={styles.inputRadioCheckbox} />
              <label htmlFor="other-area">Other. Please explain:</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExteriorForm;
