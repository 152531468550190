import React, { useState } from 'react';
import SiteDetail from './SiteDetail.js';
import SiteContact from './SiteContact.js';

function AddSite() {
  const [currentPage, setCurrentPage] = useState('detail');
  const [answer, setAnswer] = useState({
    araId: "",
    companyId: 0,
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    recheckcycle: "",
    userData: {
      fname: "",
      lname: "",
      phone: "",
      designation: "",
      email: "",
      role: ""
    }});

  const switchToContact = () => {
    setCurrentPage('contact');
  };

  const switchToDetail = () => {
    setCurrentPage('detail');
  };

  return (
    <div className="AddSite">
      {currentPage === 'detail' ? (
        <SiteDetail answer={answer} setAnswer={setAnswer} onSwitchToContact={switchToContact} />
      ) : (
        <SiteContact answer={answer} setAnswer={setAnswer} onSwitchToDetail={switchToDetail} />
      )}
    </div>
  );
}

export default AddSite;