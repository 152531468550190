import React, { useEffect, useState } from "react";
import CancelInspectionModal from "./CancelInspectionModal.js";
import ResendReminderModal from "./ResendReminderModal.js";
import AssignInspectionModal from "./AssignInspectionModal.js";
import ReassignModel from "./ReassignModel.js";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import ViewformIcon from "./img/Frame 1000006023.png";
import cancelInspectionIcon from "./img/Cancel Inspection.png";
import resendReminderIcon from "./img/Resend Reminder.png";
import assignInspectionIcon from "./img/Assign Inspection.png";
import reassignIcon from "./img/Reassign.png";
import editIcon from "./img/Edit.png";
import archiveIcon from "./img/Archive.png";
import viewInspectionIcon from "./img/Group.png";
import filterIcon from "./img/filter.png";
import viewRequestIcon from "./img/Vector (1).png";
import { NavLink, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";

const Inspections = ({ type, status }) => {
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedRows, setExpandedRows] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
  const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);
  const [reassignModelIsOpen, setReassignModalIsOpen] = useState(false);
  // const [currentEntries, setCurrentEntries] = useState([]);
  // Sorting direction

  const statusMap = {
    0: "New Request",
    1: "In-Progress",
    2: "To-Be-Approved",
    3: "Migrated",
    4: "Approved",
    5: "Waiting for Approval",
    6: "Renewal Scheduled",
    7: "Redo",
    8: "To be Approved",
    9: "Archived",
  };
  const handleReassignClick = () => {
    setReassignModalIsOpen(true);

  };

  const handleAssigninspectionClick = () => {
    setAssignModalIsOpen(true);

  };

  const handleResendClick = () => {
    setResendModalIsOpen(true);

    console.log("Resend button clicked");
  };

  const handleCloseReassignModel = () => {
    setReassignModalIsOpen(false);
  };
  const handleCloseAssignModal = () => {
    setAssignModalIsOpen(false);
  };

  const handleCloseResendModal = () => {
    setResendModalIsOpen(false);
  };

  const handleCancelClick = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDeleteRow = (id) => {
    const updatedData = requestData.filter((item) => item.id !== id);
    // setData(updatedData);
  };

  const handleConfirmCancel = () => {
    setModalIsOpen(false);
  };

  const filteredData = requestData?.length
    ? requestData.filter((item) =>
      item?.companyName?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  const sortedData = filteredData.length
    ? filteredData.sort((a, b) => {
      if (!sortColumn) return 0;
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    })
    : [];


  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleViewMore = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const getRequestsList = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestsList`,
      {},
      { type, status },
      "GET"
    );
    if (isSuccess) {
      setRequestData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");

    }
    getRequestsList();
  }, [type, status]);
  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = 3;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - 2 > 0 ? totalPages - 2 : 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <ul className="pagination">
        <li
          className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePreviousPage}
        >
          Previous
        </li>
        {pageNumbers}
        <li
          className={`pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={handleNextPage}
        >
          Next
        </li>
      </ul>
    );
  };
  return (
    <div className="inspections-container  p-0">
      <div className="controls">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <label> entries</label>
        </div>

        <span className="searchbox">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
        <img src={filterIcon} alt="" />
        <NavLink to="/add-requests" style={{ textDecoration: "none" }}>
          <button
            className="add-inspection-button"
          // onClick={() => setModalIsOpen(true)}
          >
            + Add Request
          </button>
        </NavLink>
      </div>
      <div className="bodyCo ">
        <div class="table-responsive mt-5">
          <div className="table ">
            <table className="inspections-table">
              <thead className="thead">
                <tr>
                  <th
                    style={{ width: "5%" }}
                    onClick={() => handleSortColumn("id")}
                  >
                    ID{" "}
                    {sortColumn === "id" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "10%" }}
                    onClick={() => handleSortColumn("araId")}
                  >
                    ARA ID{" "}
                    {sortColumn === "araId" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "15%" }}
                    onClick={() => handleSortColumn("company")}
                  >
                    Company{" "}
                    {sortColumn === "company" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ width: "17%" }}
                    onClick={() => handleSortColumn("site")}
                  >
                    Site{" "}
                    {sortColumn === "site" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  {(Number(status) === 4 || type === 'ALL') ? <th
                    style={{ width: "12%" }}
                    onClick={() => handleSortColumn("approvalDate")}
                  >
                    Approval Date{" "}
                    {sortColumn === "approvalDate" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th> : <></>}
                  {(Number(status) === 2 || type === 'ALL') ? <th
                    style={{ width: "12%" }}
                    onClick={() => handleSortColumn("approvalDate")}
                  >
                    Completed Date{" "}
                    {sortColumn === "CompletedDate" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th> : <></>}
                  {(Number(status) === 4 || type === 'ALL') ? <th
                    style={{ width: "16%" }}
                    onClick={() => handleSortColumn("nextInspectionDate")}
                  >
                    Next Inspection Date{" "}
                    {sortColumn === "nextInspectionDate" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th> : <></>}
                  {type === 'ALL' ? <th
                    style={{ width: "14%" }}
                    onClick={() => handleSortColumn("Status")}
                  >
                    Status{" "}
                    {sortColumn === "Status" &&
                      (sortDirection === "asc" ? "▲" : "▼")}
                  </th> : <></>}
                  <th style={{ width: "10%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.id}</td>
                    <td>{item?.araId}</td>
                    <td>{item?.companyName}</td>
                    <td>{item?.siteAddress + ' ' + item?.siteCity + ' ' + item?.siteState}</td>
                    {(Number(status) === 4 || type === 'ALL') && <td>{item?.approvedDate ? new Date(item?.approvedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}</td>}
                    {(Number(status) === 2 || type === 'ALL') && <td>{item?.completedDate ? new Date(item?.completedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}</td>}
                    {(Number(status) === 4 || type === 'ALL') && <td>{item?.inspectionDate ? new Date(item?.inspectionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}</td>}
                    {type === 'ALL' && <td>
                      <div
                        className={`status-${item?.status}`}
                      >
                        {statusMap[item?.status] || "Unknown Status"}
                      </div>
                    </td>}
                    <td>
                      {!expandedRows[item.id] ? (

                        <button
                          className="view-more-button"
                          onClick={() => handleViewMore(item.id)}
                        >
                          <NavLink to={`/request-form`} style={{ textDecoration: "none" }}>
                            <img
                              src={ViewformIcon}
                              alt="Frame"
                              title="View Form"
                              className=""
                            />
                          </NavLink>
                          <NavLink to={`/view-all-request`}>
                            <img
                              src={viewRequestIcon}
                              alt="Vector"
                              title="View Request"
                            />
                          </NavLink>
                          <NavLink
                            to="/view-inspection-request"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={viewInspectionIcon}
                              alt="Group"
                              title="View Inspection"
                            />
                          </NavLink>

                          <span className="view" style={{ textDecoration: 'none' }}>View More</span>
                        </button>
                      ) : (
                        <div className="additional-icons">
                          <img
                            src={cancelInspectionIcon}
                            alt="Cancel Inspection"
                            title="Cancel Inspection"
                            onClick={handleCancelClick}
                          />
                          <CancelInspectionModal
                            isOpen={modalIsOpen}
                            onRequestClose={handleCloseModal}
                            onConfirm={handleConfirmCancel}
                          />
                          <img
                            src={resendReminderIcon}
                            alt="Resend Reminder"
                            title="Resend Reminder"
                            onClick={handleResendClick}
                          />

                          <ResendReminderModal
                            isOpen={resendModalIsOpen}
                            onRequestClose={handleCloseResendModal}
                          />
                          <img
                            src={assignInspectionIcon}
                            alt="Additional Icon 3"
                            title="assignInspection"
                            onClick={handleAssigninspectionClick}
                          />
                          <AssignInspectionModal
                            isOpen={assignModalIsOpen}
                            onRequestClose={handleCloseAssignModal}
                          />
                          <img
                            src={reassignIcon}
                            alt="Additional Icon 4"
                            title="reassign"
                            onClick={handleReassignClick}
                          />
                          <ReassignModel
                            isOpen={reassignModelIsOpen}
                            onRequestClose={handleCloseReassignModel}
                          />
                          <NavLink to={`/edit-request`} style={{ textDecoration: "none" }}>
                            <img
                              src={editIcon}
                              alt="Additional Icon 5"
                              title="edit"
                            />
                          </NavLink>
                          <img
                            src={archiveIcon}
                            alt="Additional Icon 6"
                            title="archive"
                            onClick={() => handleDeleteRow(item.id)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {totalPages > 1 && renderPagination()}

        {/* <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[
            ...Array(Math.ceil(filteredData.length / entriesPerPage)).keys(),
          ].map((number) => (
            <button
              key={number + 1}
              onClick={() => handlePageChange(number + 1)}
              className={currentPage === number + 1 ? "active" : ""}
            >
              {number + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(filteredData.length / entriesPerPage)
            }
          >
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Inspections;
